// html,body, #app {
//     height: 100%;
// }

// .app-plus {
// 	height: 100vh;
// }

// .hv-100 {
// 	height: 100vh;
// }

body {
  a {
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }
}

// Fix for modal background
.modal {
  background-color: rgba(62, 63, 66, 0.6);
}

.plus-col-main-panel .border-bottom {
  padding: 0.75rem 0.25rem;
}

.panel-title {
  font-size: 12px;
  line-height: 18px;
  margin: 0.25rem;
}

/*
*
*
* Message
*
*/

.message-content {
  white-space: pre-wrap;
}

.message-container {
  @include make-col-ready();
  margin: 0.5rem !important;
}

.message-wrapper {
  &-customer {
    @include media-breakpoint-up(md) {
      width: 80% !important;
    }
  }

  &-agent {
    @include media-breakpoint-up(md) {
      margin-left: auto !important;
      width: 80% !important;
    }
  }
}

.message-header {
  // Display flex
  display: flex;

  // Align items center
  align-items: center;

  // Margin bottom
  margin-bottom: 0.5rem !important;

  &-name {
    cursor: pointer;
    font-weight: 500 !important;
    margin: 0.5rem !important;
    // Font size small
    font-size: 80%;
  }

  &-info {
    // Display flex
    display: flex;

    // Align items center
    align-items: center;
    margin-left: auto !important;

    // Font size small
    // font-size: 80%;
    font-weight: 400;

    // Text muted
    span {
      color: $gray-500;
    }
  }

  &-data {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
    border-left: 1px solid $gray-300;

    &.message-status {
      font-size: 100%;
    }

    span {
      font-size: 0.7rem;
    }
  }

  &-avatar {
    display: block;
    height: 28px;
    width: 28px;
    border-radius: 50% !important;
  }

  &-link {
    color: $gray-500;

    //@include hover-focus {
    //	color: $gray-800;
    //}
  }
}

.message {
  // Padding
  padding: 0.75rem !important;

  font-size: 0.875rem;
  line-height: 1.5;

  border-radius: 10px;

  // Message customer
  &-customer {
    color: $gray-900;
    background-color: $white;
    border-top-left-radius: 4px;

    // Border
    border: 1px solid #e7e8ee;
  }

  &-answer {
    // Backround color
    background-color: #dcdde6;
    // Border radius
    border-radius: 10px 0 10px 10px;
    // Border
    border: 1px solid #d0d2de;
  }

  &-draft {
    // Text color
    color: $gray-900;
    // Backround color
    background-color: $gray-200;
    // Border radius
    border-radius: 10px 10px 0 10px;
    // Border
    border: 1px solid $gray-400;
  }

  &-draft-reply {
    // Backround color
    background-color: $white;
    // Border radius
    border-radius: 10px 10px 0 10px;
    // Padding
    padding-bottom: 1px;
    // Overflow
    overflow: hidden;
  }

  &-note {
    // Background color
    background-color: #f8f9fa;
    // Border radius
    border-radius: 10px 10px 10px 0;
    // Border
    border: 1px solid #6f42c1;
  }

  &-attachments {
    margin: 0.5rem !important;
  }
}

.divider {
  background: $gray-200;
  &,
  &-vertical {
    margin: 0 8px;
    display: inline-block;
    height: 0.9em;
    width: 1px;
    vertical-align: middle;
    position: relative;
    top: -0.06em;
  }
  &-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    clear: both;
  }
}

.panel-divider {
  @include media-breakpoint-up(xxl) {
    width: 1px;
    height: 100%;
    background-color: $gray-200;
    display: block;
  }
}

.plus__icon {
  color: $gray-500;
}

.plus__emoji {
  color: $yellow;
}

.plus__icon__reply {
  color: $gray-400;
}

.nav-settings-assigned {
  color: $red;
}
.nav-settings-scheduled {
  color: $yellow;
}

.btn-emoji {
  height: 25px !important;
  margin: auto !important;
}

grid-divider {
  overflow-x: hidden; //quickfix to hide divider on left side
  position: relative;

  & > [class*="col-"]:nth-child(n + 2):after {
    content: "";
    background-color: $gray-200;
    position: absolute;
    top: 0;
    bottom: 0;

    @include media-breakpoint-down(md) {
      width: 100%;
      height: 1px;
      left: 0;
      top: -4px; // quickfix to center line between columns
    }
    @include media-breakpoint-up(md) {
      width: 1px;
      height: auto;
      left: -1px; // quickfix to hide divider on left side
    }
  }
}

// Navbar
.navbar-light {
  .navbar-brand {
    line-height: 1.75rem;
    color: $gray-900;

    //@include hover-focus {
    //  color: $gray-900;
    //}
  }

  .nav-link {
    padding-top: $nav-link-padding-y;
    padding-right: 0;
    padding-bottom: $nav-link-padding-y;
    padding-left: 0;
    font-size: $font-size-sm;
    line-height: inherit;
    color: $gray-900;
  }

  .nav-icon {
    color: $gray-500;
  }

  .btn {
    margin-right: $navbar-nav-link-padding-x;
    margin-left: $navbar-nav-link-padding-x;
  }

  .navbar-image {
    display: inline-block;
    width: 2.375rem;
    height: 2.375rem;
  }
}

.nav-divider {
  display: none;
  width: 1px;
  padding-top: $spacer;
  padding-bottom: $spacer;
  content: "";
  background-color: $gray-200;
}

.nav-link {
  color: $gray-800;
}

// Navigation left menu
.nav-left {
  // Nav link
  .nav-link {
    color: $gray-900;
    line-height: 1.375rem;

    //@include hover-focus {
    //	color: $blue;
    //}

    &.active,
    .show {
      color: $blue;
      background: #f6f9fd !important;
      box-shadow: inset 3px 0 0 0 $blue;
    }

    &.active,
    .show > .nav-count span {
      font-weight: $font-weight-bold;
      color: $gray-900 !important;
      background: #f6f9fd;
    }
  }

  // Nav count
  .nav-count {
    font-size: 0.725rem;
    color: $gray-500;
    line-height: 1.125rem;

    // Push it to right
    margin-left: auto !important;
    padding-right: 0.5rem !important;
  }

  // Nav item
  .nav-item {
    text-align: left;
    min-height: 2.4rem;

    //@include hover-focus {
    //	background: #f6f9fd;
    //}
  }

  // Nav item secondary
  .nav-item-secondary {
    // Display flex
    display: flex;
    align-items: center;
    font-weight: $font-weight-light;
    margin-left: 1.25rem;

    & .nav-link {
      font-weight: inherit;
      box-shadow: none !important;
      background: none !important;
      //@include hover-focus {
      //	font-weight: $font-weight-bold;
      //	color: $gray-900;
      //}

      & .active,
      .show {
        color: $gray-900 !important;
      }
    }

    &.active,
    .show > .nav-link {
      color: $gray-900 !important;
      font-weight: $font-weight-bold;
    }

    &.active,
    .show > .nav-count span {
      color: $gray-900 !important;
      font-weight: $font-weight-bold;
    }
  }
}

// Navigation right menu
.nav-right {
  // Nav link
  .nav-link {
    color: $gray-900;
    line-height: 1.375rem;

    //@include hover-focus {
    //	color: $blue;
    //}

    &.active,
    .show {
      color: $blue;
      background: #f6f9fd !important;
      box-shadow: inset 3px 0 0 0 $blue;
    }

    &.active,
    .show > .nav-count span {
      font-weight: $font-weight-bold;
      color: $gray-900 !important;
      background: #f6f9fd;
    }
  }

  // Nav count
  .nav-count {
    font-size: 0.725rem;
    color: $gray-500;
    line-height: 1.125rem;

    // Push it to right
    margin-left: auto !important;
    padding-right: 0.5rem !important;
  }

  // Nav item
  .nav-item {
    text-align: left;
    min-height: 2.4rem;

    //@include hover-focus {
    //	background: #f6f9fd;
    //}
  }

  // Nav item secondary
  .nav-item-secondary {
    // Display flex
    display: flex;
    align-items: center;
    font-weight: $font-weight-light;
    margin-left: 1.25rem;

    & .nav-link {
      font-weight: inherit;
      box-shadow: none !important;
      background: none !important;
      //@include hover-focus {
      //	font-weight: $font-weight-bold;
      //	color: $gray-900;
      //}

      & .active,
      .show {
        color: $gray-900 !important;
      }
    }

    &.active,
    .show > .nav-link {
      color: $gray-900 !important;
      font-weight: $font-weight-bold;
    }

    &.active,
    .show > .nav-count span {
      color: $gray-900 !important;
      font-weight: $font-weight-bold;
    }
  }
}

// Transitions
.plus-fade-enter-active {
  transition: all 0.2s ease;
}
.plus-fade-leave-active {
  transition: all 0.2s;
}
.plus-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
	/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.list-customer-message {
  .list-group-item {
    border: 0;
    border-radius: 0;
    // flex-direction: column !important;
    align-items: flex-start !important;

    // Border bottom
    border-bottom: 1px solid $gray-300 !important;

    // Fix padding
    // padding: 0 1rem;

    // Margin
    margin-bottom: 0;

    @include border-radius(0);

    &.active {
      z-index: 2; // Place active items above their siblings for proper border styling
      color: inherit;
      background-color: $list-group-active-bg;
      border: transparent;
      background-color: #f6f9fd;
      box-shadow: inset 3px 0 0 0 #1665d8;
    }
  }

  & > .list-group-item {
    &.active {
      z-index: 2; // Place active items above their siblings for proper border styling
      color: inherit;
      background-color: $list-group-active-bg;
      border: transparent;
      background-color: #f6f9fd;
      box-shadow: inset 3px 0 0 0 #1665d8;
    }
  }

  &-action {
    color: $gray-900;
    width: 100%; // For `<button>`s (anchors become 100% by default though)
    text-align: inherit; // For `<button>`s (anchors inherit)

    // Hover state
    //@include hover-focus {
    //	color: $gray-900;
    //  text-decoration: none;
    //	background-color: #f6f9fd;
    //box-shadow: inset 3px 0 0 0 $green;
    //
    //}

    &:active {
      color: $gray-900;
      background-color: #f6f9fd;
      box-shadow: inset 3px 0 0 0 $green;
    }
  }

  &-avatar {
    // // display: block;
    // height: 38px;
    // width: 38px;
    // border-radius: 50% !important;

    // Margin right
    margin-right: 1em;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
  }

  &-text {
    // Color muted
    color: $gray-500;

    // Fix font size
    line-height: 1.375rem;
    font-weight: 400;
    font-size: 0.75rem;

    // Some styles
    flex-grow: 1;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: 120px;
  }

  &-name {
    // Make font bigger
    font-weight: 500;
    font-size: 0.725rem;

    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    // Color muted
    color: $gray-600;
  }

  &-time {
    // Color muted
    color: $gray-500;
  }

  &-header {
    // Display flex
    display: flex;

    // Items center
    align-items: center;

    // Space between
    justify-content: space-between;
  }
}

.panel {
  &-customer {
    &-avatar {
      display: block;
      height: 38px;
      width: 38px;
      border-radius: 50% !important;
    }

    &-info-short {
      // Display flex
      display: flex;

      // Items center
      align-items: center;
    }

    &-info {
      // Display flex
      display: flex;

      // Column
      flex-direction: column !important;

      span {
        // Make font tiny
        font-weight: 300 !important;

        // Push left
        margin-left: 0.5rem !important;
      }
    }
  }
}

.shadow-widget {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

.plus-col-accounts {
  //   	@include make-col-ready();

  // Overflow
  // overflow-y: auto;

  //   	// Fix padding
  //   	padding-left: 0 !important;
  // 	padding-right: 0 !important;

  // Border right line
  border-right: 1px solid $gray-300 !important;

  // Make background color white
  background-color: $white !important;

  // 	@include media-breakpoint-up(sm) {
  // 	   @include make-col(4);

  // 	   // Make border bottom line on smaller screens
  //    border-bottom: 1px solid $gray-300 !important;

  // 	}

  // 	@include media-breakpoint-up(md) {
  // 	   @include make-col(3);
  // 	}

  // 	@include media-breakpoint-up(lg) {
  // 	   @include make-col(2);
  // 	}

  // 	@include media-breakpoint-up(xl) {
  // 	   @include make-col(2);
  // 	}
}

// .plus-col-main {

// 	@include make-col-ready();

// 	// Fix padding
// 	padding-left: 0 !important;
// 	padding-right: 0 !important;

// 	@include media-breakpoint-up(sm) {
// 	  @include make-col(8);
// 	}

// 	@include media-breakpoint-up(md) {
// 	  @include make-col(9);
// 	}

// 	@include media-breakpoint-up(lg) {
// 	  @include make-col(10);
// 	}

// 	@include media-breakpoint-up(xl) {
// 	  @include make-col(10);
// 	}
// }

.plus-col-main-list {
  // @include make-col-ready();

  // // Overflow
  // overflow-y: auto;

  // padding-left: 0 !important;
  // padding-right: 0 !important;

  // Make background color white
  background-color: $white !important;

  // // Make border bottom line on smaller screens
  // border-bottom: 1px solid $gray-300 !important;

  // // Border right line
  // border-right: 1px solid $gray-300 !important;

  // @include media-breakpoint-up(sm) {
  // 	@include make-col(3);
  // }

  // @include media-breakpoint-up(md) {
  //    @include make-col(3);
  // }

  // @include media-breakpoint-up(lg) {
  //    @include make-col(3);
  // }

  // @include media-breakpoint-up(xl) {
  //   @include make-col(3);
  // }
}

// .plus-col-main-message {

//   	@include make-col-ready();

//   	// Fix padding
// 	padding-left: 0 !important;
// 	padding-right: 0 !important;

//   	// Make border bottom line on smaller screens
// 	border-bottom: 1px solid $gray-300 !important;

// 	@include media-breakpoint-up(sm) {
// 	  @include make-col(9);
// 	}

// 	@include media-breakpoint-up(md) {
//     	@include make-col(9);
//  	}

//  	@include media-breakpoint-up(lg) {
// 		@include make-col(9);
// 	}

// 	@include media-breakpoint-up(xl) {
//     	@include make-col(9);
// 	}

// }

// .plus-col-main-messages {

//   	@include make-col-ready();

//   	// Fix padding
// 	padding-left: 0 !important;
// 	padding-right: 0 !important;

//   	// Make border bottom line on smaller screens
// 	border-bottom: 1px solid $gray-300 !important;

// 	@include media-breakpoint-up(sm) {
// 	  @include make-col(9);
// 	}

// 	@include media-breakpoint-up(md) {
//     	@include make-col(8);
//  	}

//  	@include media-breakpoint-up(lg) {
// 		@include make-col(9);
// 	}

// 	@include media-breakpoint-up(xl) {
//     	@include make-col(9);
// 	}

// }

// .plus-col-main-panel {

// 	// Make col
//   	@include make-col-ready();

//   	// Overflow
//   	overflow-y: auto;

// 	// Make background color white
// 	background-color: $white !important;

// 	// Make border bottom line on smaller screens
// 	border-bottom: 1px solid $gray-300 !important;

// 	// Make border left
// 	border-left: 1px solid $gray-300 !important;

// 	@include media-breakpoint-up(sm) {
// 		@include make-col(3);
// 	}

// 	@include media-breakpoint-up(md) {
//     	@include make-col(4);
//  	}

//  	@include media-breakpoint-up(lg) {
// 		@include make-col(3);
// 	}

// 	@include media-breakpoint-up(xl) {
//     	@include make-col(3);
// 	}

// }

// Messages row
.plus-messages-row {
  @include make-row();
  height: 100% !important;
  flex-direction: column;
  background-color: #fbfbfd !important;
}

// Message actions
.message-actions {
  // Make background color white
  background-color: $white !important;

  // Make border bottom line on smaller screens
  border-bottom: 1px solid $gray-300 !important;

  box-shadow: 0 1px 0 0 #eaedf3, 0 1px 3px 0 rgba(0, 0, 0, 0.05);

  // Make width max
  max-width: 100% !important;

  // Display flex
  display: flex;

  // Align items center
  align-items: center !important;
}

// Message actions
.message-actions-shadow {
  // Make background color white
  background-color: $white !important;

  box-shadow: 0 1px 0 0 #eaedf3, 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

// .message-list {

// 	// Make border bottom line on smaller screens
// 	border-bottom: 1px solid $gray-300 !important;

// 	// Scrollbar
// 	position: relative;
// 	overflow-y: auto;

// 	// Make width max
// 	max-width: 100% !important;

// 	// Padding
// 	padding: 1rem !important;
// }

.message-reply {
  // Make width max
  max-width: 100% !important;

  // Scrollbar
  position: relative;
  overflow-y: auto;
}

.message-reply-container {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  //min-height: 21.5rem;
}

.message-reply-form {
  // Shadow
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

  // Transition
  transition: 0.4s;

  //@include hover-focus {
  //
  //	// Shadow
  //	-webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  //    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  //
  //	// Transition
  //	transition: .4s;
  //}

  width: 100% !important;
}

// Message reply text
.message-reply-textarea {
  // Make font light
  font-weight: $font-weight-normal;

  color: #000000;

  // Disable resize
  //resize: none;

  // Overflow
  overflow-y: auto;

  // Make border 0
  border: 0;

  // Outline
  outline: none;

  // Shadow
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;

  // Apply minimum height
  min-height: 6rem;
}

// Plus page
.page-row {
  @include make-row();
  height: 100% !important;
  flex-direction: column;
}

// Page header
.page-header {
  // Make background color white
  background-color: $white !important;

  // Make border bottom line on smaller screens
  border-bottom: 1px solid $gray-300 !important;

  box-shadow: 0 1px 0 0 #eaedf3, 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid $gray-300;

  // Make width max
  max-width: 100% !important;

  // Display flex
  display: flex;

  // Align items center
  align-items: center !important;
}

.page-content {
  // Make border bottom line on smaller screens
  border-bottom: 1px solid $gray-300 !important;

  // Scrollbar
  position: relative;
  overflow-y: auto;

  // Make width max
  max-width: 100% !important;

  // Padding
  padding: 1rem !important;
}

.page-header-content {
  // Display flex
  display: flex;

  // Align items center
  align-items: center !important;
}

.page-title {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 400;
}

.page-icon {
  // Border
  border: 1px solid #eaedf3 !important;

  // Box Shadow
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

  // Border radius
  border-radius: 4px;

  // Display flex
  display: flex;

  // Justify content
  justify-content: center;

  // Align items
  align-items: center;

  // Padding
  padding: 0.4rem;

  // Push it to right
  margin-right: 0.5rem !important;
}

.theme-message .vdatetime-popup__header,
.theme-message .vdatetime-calendar__month__day--selected > span > span,
.theme-message .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #f6ab2f;
}

.theme-message .vdatetime-year-picker__item--selected,
.theme-message .vdatetime-time-picker__item--selected,
.theme-message .vdatetime-popup__actions__button {
  color: #f6ab2f;
}
// Fix ant notification
.ant-notification {
  z-index: 1100 !important;
}

// Macro
.navbar-macros {
  width: 25%;
  padding: 0.3rem 0;
  margin-bottom: -2px;
}

// Make pixel tools sticky
// .pixel-tools-sticky {
// 	z-index: 1080;
// }

// Make pixel tools sticky
// .pixel-tools-panel {
// 	position: relative;
// 	max-height: 495px;
// 	width: 300px;
// 	overflow-y:auto;
// }

.replyOverlay {
  z-index: -1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.replyMessageOverlay {
  z-index: 2;
  background-color: white;
}

.container-1200 {
  margin-left: auto;
  margin-right: auto;
  max-width: 1240px;
  padding-top: 2em;
}
.post-cards {
  flex: 1 0 0;
  border: 0 solid;
  display: block;
  max-width: 33.33%;
  float: left;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 12px;
  background-color: #fff;
  word-wrap: break-word;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
  position: relative;
}
.preview-header {
  padding: 15px 20px 15px;
  border-bottom: 1px solid #eeeeee;
}

.post-cards-footer {
  padding-top: 15px;
  min-height: 200px;
  max-width: 100%;
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.preview-media--image .placeholder,
.preview-media--album > div {
  padding-top: 100%;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
}

.post-preview[data-type="image"] .preview-media--image {
  display: block;
}

.text-preview {
  word-wrap: break-word;
  -webkit-line-break: after-white-space;
}

.section {
  width: 100%;
  float: left;
}

.text-area-size {
  max-width: 100%;
}

.publish-btn {
  background: #2daae1;
  text-decoration: none;
  text-align: center;
  border-radius: 0 !important;
  color: #fff !important;
  cursor: pointer;
  padding: 15px 0 15px;
  width: 100%;
  border-width: 0;
  bottom: 0;
  position: absolute;
  right: 0;
}

.customer-location {
  text-align: left;
}

.map_view {
  border: solid 2px #9ea0a5;
  border-radius: 5px;
}

.audio-player {
  max-width: 80% !important;
}

.audio-player > div > div:nth-of-type(1) {
  border-radius: 50px !important;
  padding: 10px 15px 10px 15px !important;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.card-header .nav-link {
  padding: 0.5rem 0.85rem !important;
}

.message-reply-form .card-header {
  padding: 0.75rem 0.85rem !important;
}

.parent_post .card-body {
  padding: 0 !important;
}

.list-customer-message-pagination {
  width: 100%;
  height: 40px;
  position: sticky;
  bottom: 0;
  z-index: 999;
}

.position-initial-converter {
  overflow-y: initial !important;
}

// Fix Z-index
.z-index-fix-1 {
  z-index: -1;
}

// Fix Z-index
.z-index-fix-0 {
  z-index: 0;
}

// Fix Z-index
.z-index-fix {
  z-index: 10;
}

// Fix Z-index
.z-index-fix-1000 {
  z-index: 1000;
}

// Fix Z-index
.z-index-fix-1100 {
  z-index: 1100;
}

// Fix Z-index
.Confirmation-time-fix {
  // Font size small
  font-size: 80%;
  font-weight: 400;

  // Text muted
  span {
    color: $gray-500;
  }
}

// Reports Information Card
.Information-card {
  // radius
  border-radius: 10px;
}

// Main Logo Color
.logo-color {
  color: #ff1d5e !important;
}

.assigned-alignment {
  vertical-align: sub;
}

.pointer {
  cursor: pointer;
}

.instagram-select-account-card > .card-body {
  cursor: pointer;
  padding: 0 !important;
}

.instagram-select-account-card > .card-body:hover {
  background: #f1f1f1;
}

.instagram-select-account-image {
  padding: 1rem 4rem 1rem 2rem;
}

.instagram-select-account-card-body {
  padding-top: 0.5rem !important;
}

.instagram-select-account-card-body > p {
  margin-bottom: 0 !important;
}

.instagram-select-account-card-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  line-height: 2rem !important;
  margin-bottom: 0 !important;
}

// Start Responsive

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.plus-col-main-list-inbox-container,
.plus-col-main-message,
.plus-col-main-messages-panel {
  padding: 0 !important;
}

.login-plus-main {
  height: 100vh;
}

.login-plus-main-card {
  width: 300px;
}

.plus-navbar {
  min-height: 4rem;
}

@supports (position: sticky) {
  .plus-navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
  }
}

.player-all-in-one {
  position: relative;
  padding: 0.5rem 1rem;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid $gray-300 !important;
}

.actions-main-menu {
  position: relative;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $gray-300 !important;
}

.customer-render-username {
  // word-break: break-all;
  white-space: normal;
}

.actions-message-menu {
  position: relative;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid $gray-300 !important;
}

.plus-navbar-left {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

.plus-sidebar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

#plus-col-accounts-sidebar-mobile {
  top: 7px;
}

.plus-navbar-brand {
  margin: 0;
}

.plus-navbar-left-customer-message {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  min-width: 200px;
  border-right: 1px solid #eaedf3 !important;
}

.plus-col-accounts-mobile-view,
.plus-col-main-messages-panel,
.plus-col-main-list-inbox-container {
  border-right: 1px solid $gray-300 !important;
}

.plus-col-main-list-pagination-button {
  border-top: 1px solid $gray-300 !important;
}

.plus-main-message-list {
  height: calc(100vh - 10rem);
  overflow-y: auto;
}

#plus-header-nav-collapse-tablet {
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
}

.plus-col-main-list-inbox-main-menu,
#plus-col-main-list,
.plus-col-main-messages-panel,
.plus-col-main-messages-panel-mobile {
  display: flex;
  flex-direction: column;
}

.plus-header-navbar-tablet,
#plus-header-nav-collapse-tablet {
  top: 4rem;
  background-color: #fff !important;
}

.plus-header-accordion-container {
  background-color: #fff !important;
}

.plus-header-accordion-header {
  border-bottom: 1px solid $gray-300 !important;
}

.plus-header-accordion-button:hover,
.plus-header-accordion-links {
  color: $gray-800;
}

.plus-header-accordion-links.active {
  color: #1665d8;
}

.plus-header-accordion-card,
.plus-header-accordion-header {
  background-color: #fff !important;
  color: $gray-500;
  // border: none !important;
  padding: 0.25rem 0;
}
.plus-header-accordion-button {
  background-color: #fff !important;
  color: $gray-900;
  border: none !important;
  padding: 0.25rem 0;
}

.plus-header-accordion-collapse-menu {
  margin: -0.3rem 0 !important;
}

.modal {
  z-index: 1100 !important;
}

.modal-user-edit {
  height: 70vh;
  overflow-y: auto;
}

.information-card-header {
  min-height: 8vh;
  padding: 0.5rem 0.5rem !important;
}

.information-title-icon {
  font-size: 0.75em !important;
}

@supports (position: sticky) {
  .plus-col-main-list-inbox-main-menu,
  .plus-col-main-messages-panel {
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
  }

  .plus-page-header,
  .plus-col-main-panel-aside {
    top: 4rem;
  }
}

@include media-breakpoint-down(lg) {
  .plus-col-accounts-mobile-view {
    position: relative;
    top: 3.1rem;
    height: calc(100vh - 3.1rem);
  }
}

@include media-breakpoint-up(md) {
  .login-plus-main-card {
    width: 350px;
  }

  .login-form-language {
    width: 35%;
  }
  .plus-navbar-left {
    max-height: calc(100vh - 7.5rem);
    overflow-y: auto;
  }

  #user-info-sidebar-right-tablet {
    max-height: calc(100vh - 5rem);
    overflow-y: auto !important;
  }

  @supports (position: sticky) {
    .plus-col-main-list-inbox-main-menu,
    .plus-col-main-messages-panel,
    .plus-col-accounts {
      position: sticky;
      top: 4rem;
      height: calc(100vh - 4rem);
    }
  }
}

@include media-breakpoint-down(md) {
  .convoreport {
    font-size: 0.5rem;
  }
}

@include media-breakpoint-down(sm) {
  .plus-navbar-brand-img {
    width: 100px;
  }

  .actions-message-menu {
    border-top: 1px solid $gray-300 !important;
  }

  .customer-message-avatar-mobile,
  .agent-message-avatar-mobile {
    display: none !important;
  }

  .confirmation-header-text,
  .notification-header-text,
  .settings-categories-header {
    font-size: 1rem;
  }

  .settings-categories-buttons {
    padding: 0.25rem 0.25rem;
    font-size: 0.7rem;
    line-height: 1.375;
    border-radius: 0.2rem;
  }

  .mx-range-wrapper > div:nth-child(2) {
    display: none;
  }

  .mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 0 !important;
  }

  .mx-datepicker-sidebar {
    display: none;
  }

  .information-card-header {
    min-height: 5vh;
  }
}

@include media-breakpoint-up(xl) {
  .information-card-header {
    min-height: 5vh;
  }
}

@include media-breakpoint-down(sm) {
  .report-text {
    font-size: medium;
  }

  .css-17x29rq {
    font-size: 0.7rem;
  }
}

@include media-breakpoint-only(md) {
  .report-text-container {
    width: 100%;
  }
}

.convoreport {
  width: 100%;
  min-height: 50vh;
}

.report-overlay {
  opacity: 0.7;
  text-align: center;
}

.report-overlay:after {
  content: "Sample Data";
  display: inline-block;
  position: relative;
  top: 4rem;
  font-size: 4rem;
  opacity: 0.5;
  color: black;
  transform: rotate(-30deg);
}

.map-report-overlay:after {
  content: "Sample Data";
  display: inline-block;
  position: relative;
  top: 30%;
  left: 30%;
  font-size: 4em;
  opacity: 0.7;
  color: red;
  transform: rotate(-30deg);
}

// End Responsive

// Whatsapp Start

.whatsapp-business {
  &.profile-picture {
    &.btn {
      font-size: 0.7em;
      @include media-breakpoint-down(md) {
        font-size: 0.5em;
      }
    }
  }
}

.template-header-info {
  font-size: 90%;
}

.media-upload-button {
  opacity: 0;
}

.media-upload-container {
  position: relative;
  width: 200px;
  height: 104.5px;
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  border-color: #38b249;
  box-shadow: 0 0 0 0.1rem rgba(56, 178, 73, 0.25);
  background-color: #f5f5f5;
}

.media-upload-container.isInvalid {
  border-color: #e6492d;
  box-shadow: 0 0 0 0.1rem rgba(230, 73, 45, 0.25);
}

.media-upload-inner-container {
  display: block;
  font-size: 0.7em;
}

.media-preview {
  width: 200px;
  height: 104.5px;
  object-fit: contain;
}

.media-preview-container {
  width: 202px;
  height: 106.5px;
  border-style: solid;
  border-width: 1px;
  border-color: #eaedf3;
  background-color: #fbfbfd;
}

.media-preview-container-header {
  width: 100%;
  height: 106.5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #eaedf3;
  background-color: #f8f9fa;
}

.preview-remove-button {
  top: 0;
  right: 0;
}

.preview-video-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.75rem;
  color: $gray-400;
}

.coolpicker-button {
  position: absolute;
  bottom: -10px;
  right: 0;
}

.coolpicker-button.interactive-list {
  position: absolute;
  bottom: -15px;
  right: -10px;
}

.coolpicker-button-reply-area {
  right: 0;
  bottom: -30px;
}

#btn-emoji-default > div > img.emoji {
  height: 24px !important;
  width: 24px !important;
}

.list-item-remove-button {
  top: 5px;
  right: 5px;
}

.campaign-nav-left {
  height: calc(100vh - 5rem);
}

.media-video-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: $gray-400;
}
.my-sm-1 {
  margin-top: 8px;
  margin-bottom: 8px;

  @include media-breakpoint-up(sm) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
.whatsapp-campaign{
  .custom-control-inline{
    margin-right: 0.5rem;
    @include media-breakpoint-up(sm) {
     margin-right: 1rem;
    }
  }
}
.whatsapp-campaign-details-container{
  .pagination{
    margin-bottom: 1rem ;
    @include media-breakpoint-up(md) {
      margin-bottom: 0px;
    }
  }
}
