// New editor preview

/**
 * A new editor preview
 */
.fullscreen-v-editor {
  z-index: 1100;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: #eff3f6;
  -ms-touch-action: none;
  touch-action: none;
}

.canvas-container {
  position: relative;
  margin-bottom: 1.875rem;
  padding: 1.25rem;
  outline: 2px dashed #1890ff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}  

.v-editor-fade-enter,
.v-editor-fade-leave-to {
  opacity: 0;
}
.v-editor-fade-enter-active,
.v-editor-fade-leave-active {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

// Responsive Design Start

.fullscreen-v-editor {
  overflow: auto;
}






// Responsive Design End