// New video preview

/**
 * A new video preview
 */
.fullscreen-v-video {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  -ms-touch-action: none;
  touch-action: none;
}
.content-v-video, .video-player-box{
  width: auto;
  height: auto;
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.header-v-video {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
}
.header-v-video {
  justify-content: space-between;
}
.title-v-video {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-align: center;
  max-height: 100%;
  overflow: auto;
}
.buttons-v-video {
  width: 80px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
.buttons-v-video {
  margin-right: 10px;
  text-align: right;
}
.buttons-v-video span path {
  fill: #e5e6eb;
  -webkit-transition: fill 0.4s ease-in-out;
  transition: fill 0.4s ease-in-out;
}
.buttons-v-video span {
  cursor: pointer;
  color: #e5e6eb;
  font-size: 30px;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
  text-decoration: none;
  text-align: center;
}
.buttons-v-video span:not(:last-child) {
  margin-right: 8px;
}
.buttons-v-video span svg {
  height: 20px;
  width: 15px;
}
.buttons-v-video span:hover svg path {
  fill: white;
}
.buttons-v-video span:hover {
  color: white;
}

.v-video-fade-enter,
.v-video-fade-leave-to {
  opacity: 0;
}
.v-video-fade-enter-active,
.v-video-fade-leave-active {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}