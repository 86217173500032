@import 'plus_variables';
@import '~bootstrap/scss/bootstrap';
@import 'custom';
@import 'notifications';
@import 'image';
@import 'editor';
@import 'video';
@import '~bootstrap-vue/dist/bootstrap-vue.css';

// Import multiselect
@import "~vue-multiselect/dist/vue-multiselect.min.css";

// Import vue tree select
@import "~@riophae/vue-treeselect/dist/vue-treeselect.min.css";

// Import pretty checkbox
// Video css
@import '~video.js/dist/video-js.css';

// Perfect scroll bar
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Import vue date time
@import '~vue-datetime/dist/vue-datetime.css';

// Import filepond
@import '~filepond/dist/filepond.min.css';

// Ant
@import '~ant-design-vue/dist/antd.css';



