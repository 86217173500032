@import '../../style/themes/index';
@import '../../style/mixins/index';

@radio-prefix-cls: ~'@{ant-prefix}-radio';
@radio-group-prefix-cls: ~'@{radio-prefix-cls}-group';
@radio-inner-prefix-cls: ~'@{radio-prefix-cls}-inner';
@radio-duration: 0.3s;
@radio-focus-shadow: 0 0 0 3px fade(@radio-dot-color, 8%);
@radio-button-focus-shadow: @radio-focus-shadow;

.@{radio-group-prefix-cls} {
  .reset-component;

  display: inline-block;
}

// 一般状态
.@{radio-prefix-cls}-wrapper {
  .reset-component;

  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.@{radio-prefix-cls} {
  .reset-component;

  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;

  .@{radio-prefix-cls}-wrapper:hover &,
  &:hover .@{radio-inner-prefix-cls},
  &-input:focus + .@{radio-inner-prefix-cls} {
    border-color: @radio-dot-color;
  }

  &-input:focus + .@{radio-inner-prefix-cls} {
    box-shadow: @radio-focus-shadow;
  }

  &-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid @radio-dot-color;
    border-radius: 50%;
    visibility: hidden;
    animation: antRadioEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: '';
  }

  &:hover::after,
  .@{radio-prefix-cls}-wrapper:hover &::after {
    visibility: visible;
  }

  &-inner {
    &::after {
      @radio-dot-size: @radio-size - 8px;

      position: absolute;
      top: (@radio-size - @radio-dot-size) / 2 - 1px;
      left: (@radio-size - @radio-dot-size) / 2 - 1px;
      display: table;
      width: @radio-dot-size;
      height: @radio-dot-size;
      background-color: @radio-dot-color;
      border-top: 0;
      border-left: 0;
      border-radius: @radio-dot-size;
      transform: scale(0);
      opacity: 0;
      transition: all @radio-duration @ease-in-out-circ;
      content: ' ';
    }

    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: @radio-size;
    height: @radio-size;
    background-color: @radio-button-bg;
    border-color: @border-color-base;
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    transition: all @radio-duration;
  }

  &-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
}

// 选中状态
.@{radio-prefix-cls}-checked {
  .@{radio-inner-prefix-cls} {
    border-color: @radio-dot-color;
    &::after {
      transform: scale(1);
      opacity: 1;
      transition: all @radio-duration @ease-in-out-circ;
    }
  }
}

.@{radio-prefix-cls}-disabled {
  .@{radio-inner-prefix-cls} {
    background-color: @input-disabled-bg;
    border-color: @border-color-base !important;
    cursor: not-allowed;
    &::after {
      background-color: fade(@black, 20%);
    }
  }

  .@{radio-prefix-cls}-input {
    cursor: not-allowed;
  }

  & + span {
    color: @disabled-color;
    cursor: not-allowed;
  }
}

span.@{radio-prefix-cls} + * {
  padding-right: 8px;
  padding-left: 8px;
}

.@{radio-prefix-cls}-button-wrapper {
  position: relative;
  display: inline-block;
  height: @btn-height-base;
  margin: 0;
  padding: 0 @padding-md - 1px;
  color: @radio-button-color;
  line-height: @btn-height-base - 2px;
  background: @radio-button-bg;
  border: @border-width-base @border-style-base @border-color-base;
  // strange align fix for chrome but works
  // https://gw.alipayobjects.com/zos/rmsportal/VFTfKXJuogBAXcvfAUWJ.gif
  border-top-width: @border-width-base + 0.02px;
  border-left: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;

  a {
    color: @radio-button-color;
  }

  > .@{radio-prefix-cls}-button {
    display: block;
    width: 0;
    height: 0;
    margin-left: 0;
  }

  .@{radio-group-prefix-cls}-large & {
    height: @input-height-lg;
    font-size: @font-size-lg;
    line-height: @input-height-lg - 2px;
  }

  .@{radio-group-prefix-cls}-small & {
    height: @input-height-sm;
    padding: 0 @control-padding-horizontal-sm - 1px;
    line-height: @input-height-sm - 2px;
  }

  &:not(:first-child) {
    &::before {
      position: absolute;
      top: @border-width-base * -1;
      left: -1px;
      display: block;
      box-sizing: content-box;
      width: 1px;
      height: 100%;
      padding: @border-width-base 0;
      background-color: @border-color-base;
      transition: background-color 0.3s;
      content: '';
    }
  }
  &:first-child {
    border-left: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base 0 0 @border-radius-base;
  }

  &:last-child {
    border-radius: 0 @border-radius-base @border-radius-base 0;
  }

  &:first-child:last-child {
    border-radius: @border-radius-base;
  }

  &:hover {
    position: relative;
    color: @radio-dot-color;
  }

  &:focus-within {
    box-shadow: @radio-button-focus-shadow;
  }

  .@{radio-prefix-cls}-inner,
  input[type='checkbox'],
  input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }

  &-checked:not(&-disabled) {
    z-index: 1;
    color: @radio-dot-color;
    background: @radio-button-checked-bg;
    border-color: @radio-dot-color;

    &::before {
      background-color: @radio-dot-color;
    }

    &:first-child {
      border-color: @radio-dot-color;
    }

    &:hover {
      color: @radio-button-hover-color;
      border-color: @radio-button-hover-color;
      &::before {
        background-color: @radio-button-hover-color;
      }
    }

    &:active {
      color: @radio-button-active-color;
      border-color: @radio-button-active-color;
      &::before {
        background-color: @radio-button-active-color;
      }
    }

    &:focus-within {
      box-shadow: @radio-button-focus-shadow;
    }
  }

  .@{radio-group-prefix-cls}-solid &-checked:not(&-disabled) {
    color: @component-background;
    background: @radio-dot-color;
    border-color: @radio-dot-color;
    &:hover {
      color: @component-background;
      background: @radio-button-hover-color;
      border-color: @radio-button-hover-color;
    }
    &:active {
      color: @component-background;
      background: @radio-button-active-color;
      border-color: @radio-button-active-color;
    }
    &:focus-within {
      box-shadow: @radio-button-focus-shadow;
    }
  }

  &-disabled {
    color: @disabled-color;
    background-color: @input-disabled-bg;
    border-color: @border-color-base;
    cursor: not-allowed;

    &:first-child,
    &:hover {
      color: @disabled-color;
      background-color: @input-disabled-bg;
      border-color: @border-color-base;
    }
    &:first-child {
      border-left-color: @border-color-base;
    }
  }

  &-disabled&-checked {
    color: @text-color-inverse;
    background-color: tint(@black, 90%);
    border-color: @border-color-base;
    box-shadow: none;
  }
}

@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

// Firefox hack
@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
  .@{radio-prefix-cls} {
    vertical-align: text-bottom;
  }
}
