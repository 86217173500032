.fullscreen-v-img {
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  -ms-touch-action: none;
  touch-action: none;
}
.content-v-img img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.header-v-img,
.footer-v-img {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  height: 50px;
  z-index: 9999;
  display: flex;
  align-items: center;
}
.header-v-img {
  justify-content: space-between;
}
.footer-v-img {
  bottom: 0;
  justify-content: center;
  height: 70px;
  /* scrolling thumbnails on mobile */
  overflow-x: auto;
}
.footer-v-img img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  -webkit-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  object-fit: cover;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.footer-v-img img.is-selected {
  transform: scale(1.1);
}
.footer-v-img img:not(:last-child) {
  margin-right: 7px;
}
.title-v-img {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: white;
  text-align: center;
  max-height: 100%;
  overflow: auto;
}
.count-v-img,
.buttons-v-img {
  width: 80px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
}
.count-v-img {
  font-size: 15px;
  color: white;
  margin-left: 10px;
}
.buttons-v-img {
  margin-right: 10px;
  text-align: right;
}
.buttons-v-img span path {
  fill: #e5e6eb;
  -webkit-transition: fill 0.4s ease-in-out;
  transition: fill 0.4s ease-in-out;
}
.buttons-v-img span {
  cursor: pointer;
  color: #e5e6eb;
  font-size: 30px;
  -webkit-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;
  text-decoration: none;
  text-align: center;
}
.buttons-v-img span:not(:last-child) {
  margin-right: 8px;
}
.buttons-v-img span svg {
  height: 20px;
  width: 15px;
}
.buttons-v-img span:hover svg path {
  fill: white;
}
.buttons-v-img span:hover {
  color: white;
}
.prev-v-img svg,
.next-v-img svg {
  margin: 5px auto;
}
.prev-v-img,
.next-v-img {
  color: white;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  margin-top: -12.5px;
  font-size: 15px;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  opacity: 0.3;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
}
.prev-v-img:hover,
.next-v-img:hover {
  opacity: 1;
}
.prev-v-img {
  left: 10px;
}
.next-v-img {
  right: 10px;
}
.v-img-fade-enter,
.v-img-fade-leave-to {
  opacity: 0;
}
.v-img-fade-enter-active,
.v-img-fade-leave-active {
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}