$gray-200: #eaecee;
$gray-300: #eaedf3;
$gray-500: #9ea0a5;
$gray-700: #bcbcbc;
$gray-800: #6b6c6f;
$gray-900: #3e3f42;
$blue:    #1665d8;
$red:     #e6492d;
$orange:  #f6ab2f;
$yellow:  #facf55;
$green:   #38b249;
$white:   #fff;
$black:   #000;
$body-bg: #fbfbfd;
$line-height-lg: 1.375;


$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$line-height-sm: 1.375;
$font-family-sans-serif:  "Roboto", sans-serif;

$font-size-base: .875rem; // Assumes the browser default, typically `16px`

$font-weight-bold: 500;

$headings-line-height: 2.375;

$text-muted: $gray-500;

$blockquote-small-color:   $gray-500;

$btn-font-weight: $font-weight-light;

$btn-link-disabled-color:     $gray-500;

$input-color:  $gray-900;

$input-border-color: #e2e5ed;

$input-box-shadow: inset 0 1px 1px rgba(102, 113, 123, .21);


$input-placeholder-color: $gray-500;

$custom-control-label-disabled-color: $gray-500;


$custom-select-disabled-color:      $gray-500;

$dropdown-border-color:             #d8dce6;

$dropdown-box-shadow:               0 .5rem 1rem 0 rgba($black, .08);

$dropdown-link-disabled-color:      $gray-500;

$dropdown-header-color:             $gray-500;

$nav-link-disabled-color:           $gray-500;

$navbar-padding-y:                  .5rem;

$navbar-padding-x:                  2rem;

$navbar-nav-link-padding-x:         0.85rem;

$navbar-brand-font-size:            1.125rem;

$navbar-light-color:                $gray-900;

$navbar-light-hover-color:          $blue;

$navbar-light-active-color:         $blue;

$pagination-disabled-color:         $gray-500;

$tooltip-bg:                        $gray-900;

$modal-content-border-color:        rgba($white, .2);

$list-group-disabled-color:         $gray-500;

$figure-caption-color:              $gray-500;

$breadcrumb-divider-color:          $gray-500;

$breadcrumb-active-color:           $gray-500;

$close-color:                       $gray-500;


$grid-breakpoints: ();

$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
  ),
  $grid-breakpoints
);

$container-max-widths: ();

$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px,
  ),
  $container-max-widths
);

$nav-tabs-link-active-color:        $gray-800;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

$card-border-color:                 $gray-300;

	



